.vve-dialpad-container {
    width: 31rem;
    background-color: $vve-white;
    @extend .vve-medium;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .vve-dialpad-header {
        font-size: 1.3rem;
        line-height: 22px;
        @media screen and ($mobile-landscape){
            line-height: 17px;
        }
        letter-spacing: -0.01em;
        color: $vve-black;
        font-weight: 700;
    }

    .vve-phonenumber-wrapper {
        width: 100%;
        margin: 20px auto auto;
        @media screen and ($mobile-landscape){
            margin: 11px auto auto;
        }
        position: relative;
        display: flex;
        input {
            font-size: 2rem;
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
            width: calc(100% - 2.5rem);
            text-align: center;
        }
    }

    .vve-keypad-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0px 0;
        @media screen and ($mobile-landscape){
            padding: 10px 0px 0;
        }
        justify-content: center;

        .vve-pad-key-wrapper {

            background: $vve-white;
            border: 0.5px solid $vve-light-grey;

            button {
                &:not(.vve-dialpad-hash):not(.vve-dialpad-star) {
                    background: $vve-white;
                }

                border: 0.5px solid $vve-light-grey;
            }

            .vve-key-icon {
                text-align: center;
                vertical-align: middle;
                line-height: 1.25;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                overflow: hidden;
                filter: opacity(1) drop-shadow(0 0 0 black);
                min-height: 5rem;
                color: $vve-black;
            }

            .vve-dial-number {
                font-size: 2rem;
                position: relative;
                top: 0.8rem;
                @extend .vve-medium;
            }

            .vve-dial-alpha {
                font-size: 1rem;
                @extend .vve-light;
            }

            .vve-dial-button {
                border: none;
                width: 10rem;
            }
        }

    }
}