.vve-abridge-container {
    display: flex;
    flex-direction: column;

    .vve-patient-consent {
        font-family: Lato-Bold;
        font-size: 15px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;
        color: $vve-black;
        padding: 15px 3px 10px;
        border-bottom: 1px solid $vve-abridge-border;
    }

    .vve-abridge-consent {
        display: flex;
        flex-direction: column;
        padding: 3px;
        flex: 1;
        .vve-abridge-consent-img{
            padding-right: 0.6rem;
            @media ($mobile) {
                padding-right: 0.3rem;
            }
        }

        .vve-abridge-consent-header {
            gap: 5px;
            align-items: center;
                font-family: Lato-Bold;
                font-size: 13px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
                color: $vve-black;
                margin-bottom: 0;
        }
        .vve-abridge-consent-info {
            padding-top: 12px;
            font-family: Lato-Regular;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: $vve-black;
        }
    }

    @media (orientation: landscape) {
        .vve-abridge-consent-info br:first-of-type {
            display: none;
        }

        .vve-patient-consent {
            padding: 1px;
        }

        .vve-abridge-consent {
            padding: 0;
        }
    }
}