.add-guest-container {
    font-size: 1.3rem;
    line-height: 2rem;
    height: 100%;

    .form-check-inline {
        color: $vve-black;
        @extend .vve-medium;
        font-weight: 700;
        line-height: 1.95rem;
        .form-check-input {
            &:checked {
                background-color: $vve-vivid-blue;
                border-color: $vve-vivid-blue;
                background-image: url("../../assets/images/vve-radio-btn.svg");
            }
        }
    }

    .vve-smalldesk-dialpad-container {
        @media screen and ($device-medium){
            margin: auto;
            width: 20rem;
        }
        .vve-smalldesk-keypad-wrapper {
            .vve-dial-button {
            @media screen and ($device-medium){
                width: 6rem;
            }
        }
    }
    }
}