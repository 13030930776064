.vve-detailedInfo-container {
  border-radius: 8px;
  background: $vve-white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
  width: 680px;
  height: 821px;

  @media ($mobile) {
    width: 100%;
    margin-top: 1rem;
    box-shadow: none;
  }

  .vve-detailedInfo-header {
    font-size: 16px;
    font-weight: 500;
    height: 34px;
    border-radius: 8px 8px 0px 0px;
    background-color: $vve-bgcolor-calendar;
    color: $vve-black;

    @media ($mobile) {
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    }

    p {
      margin: auto;
      padding-top: 0.5rem;
      display: flex;
      justify-content: center;
      font-family: "Lato-Bold";
    }
  }

  .vve-drphoto {
    width: 86px;
    height: 86px;
    flex-shrink: 0;
    border-radius: 50%;
    border: none;
    justify-content: center;
    display: flex;
    margin: auto;
    margin-top: 2rem;

    .vve-icon {
      width: 86px;
      height: 86px;
      border-radius: 50%;
      margin: auto;
    }

    &.vve-live {
      border: 2.5px solid $vve-oncall-green;
      width: 96px;
      height: 96px;
    }
  }

  .vve-firstname {
    display: flex;
    padding: 10px;
    justify-content: center;
    font-family: "Lato-Bold";
    color: $vve-black;
    font-weight: 500;

    .vve-frstnamelastname-div {
      margin-right: 10px;
    }

    p {
      display: flex;
      padding: 8px 5px;
      align-items: flex-end;
      border-radius: 7px;
      background: $vve-oncall-green;
      color: $vve-oncall-selected-date-color;
      font-weight: 500;
      font-size: 10px;
      font-style: normal;
      position: relative;
      top: 8px;
      line-height: 0px;
      height: 15px;
      @media ($mobile) {
        top: 4px;
      }
    }
  }

  .vve-role {
    display: flex;
    justify-content: center;
    color: $vve-caregiver-role-grey;
    font-family: "Lato-Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.32px;
  }

  .vve-callorder,
  .vve-name {
    display: flex;
    justify-content: center;
    color: $vve-caregiver-role-grey;
    font-family: "Lato-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.32px;
    margin-bottom: 2rem;
  }

  .vve-name {
    font-family: "Lato-Bold";
  }
  
  .vve-contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    border-top: 1px solid $vve-department-list-group;
    font-size: 13px;
    min-height: 62px;
    margin: 0 2.5rem;
    padding: 10px 23px 10px 7px;

    &:last-child {
      border-bottom: 1px solid $vve-department-list-group;
    }

    p {
      margin: 0;
      text-wrap: wrap;
      white-space: normal;
    }

    p.black-text {
      color: $vve-black;
      font-family: "Lato-Bold";
      font-weight: 500;
      line-height: 19px;
    }

    p.green-text {
      color: $vve-oncall-green;
      font-family: "Lato-Bold";
      font-weight: 500;
      line-height: 19px;
    }

    a.blue-text {
      color: $vve-tabs-color-blue-active;
      font-family: "Lato-Bold";
      font-weight: 500;
      line-height: 19px;
    }

    @media ($mobile) {
      margin: 0;
      padding-left: 10px;
      padding-right: 13px;
    }
    
    .vve-video-icon {
      margin-right: 1.5rem;
      cursor: pointer;
    }

    .vve-call-icon {
      margin-right: 1.5rem;
      cursor: pointer;
    }

    .vve-copypaste-icon {
      background-image: url("../../assets/images/copy-small.svg");
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .vve-contacts-header {
      color: $vve-black;
      line-height: 19px;
      font-weight: 500;
    }
  }

  @media ($mobile) {
    align-self: center;
  }

  .vve-cursor-pointer {
    cursor: pointer;
  }
}