.vve-asl-language-text-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    @media screen and ($mobile-landscape){
        overflow-y: auto;
    }
   .vve-asl-text {
        font-size: 1.23rem;
        font-weight: 400;
        letter-spacing: -0.02em;
        text-align: left;
        line-height: 1.89rem;
        width: 100%;
    }
    b {
        font-family: Lato-Bold;
        color: $vve-black;
        font-weight: 700;
    }
}