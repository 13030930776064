// application
$kp-blue:#0078b3;
$btn-color: #006aa5;
$vve-blue: #006CE5;
$vve-grey: #69697B;
$vve-white: #FFFFFF;
$vve-light-grey: #E1E1E1;
$vve-light-black: #0D1C3D;
$very-dark-blue: #0A1940; 
$vve-vivid-blue: #1B75E9;
$vve-black: #000000;
$vve-black-btn-font: #232323;
$vve-bluish-white: #EEEFF2;
$vve-bright-grey: #98A0A9;
$vve-red: #FF0000;
$vve-bright-red:#B63125;
$vve-light-red:#fdf4f3;
$vve-modal-btn: #0E72ED;
$border-silver: #dee2e6;
$button-disabled: #EFEFF4;
$input-disabled: #E9ECEF;
$button-disabled-font: #909096;
$button-disabled-grey: #DDDDDD;
$disable-off-white: #F2F2F2;
$vve-caregiver-role-grey: #70788A;
$vve-oncall-green: #208819;
$error-page-color:#212529;
$snow-category-header-border: #ced4da;
$snow-category-list-border: #C1C1C1;
$redirect-text-color:#000;
$vve-fav-background:#677083;
$vve-fav-toggle-backround: #1B75E9;
$vve-abridge-border: #DFE3E8;
$font-size-base: 1.6rem !default;
$font-family-base: "Lato-Regular" !default;
$vve-tabs-color-black-inactive: #000000;
$vve-tabs-bgcolor-grey-inactive: #D7D7D7;
$vve-tabs-color-blue-active: #0B5CFF;
$vve-bgcolor-calendar:  #EEEFF2;
$vve-calender-header-color:  #938B96;
$vve-oncall-selected-date-color: #fff;
$vve-department-list-group: #D9D9D9;
$vve-oncall-selected-dept: #F6F6F6;
$vve-filter-grey: #C4C4C4;
$vve-filtermodal-black-color: #232323;
$vve-noresult-found-text: #747485;
$vve-new-menu-bgcolor: #E4EEFC;
$vve-error-bgcolor: #FCF6F6;
$vve-guest-patient-btn-disabled-bgcolor: #EFEFF3;
$vve-guest-patient-btn-remove-color: #3073E5;
$vve-guest-patient-msg-color:#D00000;
//bootstrap overwrite
$theme-colors: (
  "primary": $btn-color
);
$divider:#BDBDBD;
$group-class-text:#70788a;
$download-btn:#1B75E9;
$table-background: #f7f7f7;
$vve-date-picker-day-name-color: #ABABAB;