.vve-group-class-table {
  tbody tr:nth-child(even) {
    background-color: $table-background;
  }
  .vve-tool-tip-group-class-table {
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
    cursor: pointer;
    @media ($mobile) {
      max-width: none;
    }
  }
  .vve-drop-column {
    color: #0050f0;
    cursor: pointer;
  }
  .vve-drop-column:hover {
    color: #0050f0;
  }
}
