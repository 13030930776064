.vve-language-list {
    height: calc(100% - 14.8rem);
    overflow-y: auto;
    button {
        color: $vve-black;
        @extend .vve-medium;
        font-weight: 700;
        border: none;
        &.active {
            background: $vve-vivid-blue;
            border-radius: 1rem;
        }
    }
}

.vve-asl-language-list {
    height: calc(100% - 14.8rem);
    overflow-y: auto;
    border-radius: 1.25rem;
    div {
        color: $vve-black;
        @extend .vve-medium;
        font-weight: 700;
        border: none;
        pointer-events: none;
        background: $vve-bluish-white;
        color: $vve-black;
    }
}


