.vve-tooltip-card {
  position: fixed;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 28vw;
  left: 54vw;
  top: calc(100% - 30vw);
  color: black;
  border-radius: 0.5rem;

  .vve-card-text-bold {
    @extend .vve-bold;
    font-family: "Lato-Bold";
    padding: 0;
  }

  .vve-total-attended-text {
    font-size: 1.1rem;
    @extend .vve-bold;
    font-family: "Lato-Bold";
    padding: 0;
    padding-left: 9rem;
    text-align: center;
  }
  .card-header {
    text-align: left;
    border: none;
    font-size: 1.3rem;
    font-weight: 500;
    background: none;
    padding-bottom: 0px;
    margin-bottom: 0.4rem;
  }
  .card-body {
    border: none;
    max-height: 21rem;
    overflow-y: auto;
    padding: 0px 1rem;
    background: none;
  }
  .card-footer {
    border: none;
    background: none;
    padding-bottom: 1.5rem;
  }
  .vve-close-btn {
    border: none;
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.6rem;
    border-radius: 0.8rem;
    background-color: $download-btn;
    font-variant-caps: all-small-caps;
    width: 12vw;
    color: $vve-white;
    border: none;
  }
  .vve-tool-tip-table {
    tbody {
      font-size: 1.1rem;
      text-align: center;
    }
    tbody tr:nth-child(even) {
      background-color: $table-background;
    }
    thead {
      @extend .vve-bold;
      background: #deedff;
      text-align: center;
    }
    thead th {
      padding: 5px 10px;
      line-height: 1.2;
    }
    thead tr {
      height: 30px;
    }
    th, td {
      width: 33.33%; 
      text-align: center; 
    }
  }
}