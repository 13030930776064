.vve-department-list {
    max-height: 733px;
    overflow-y: auto;

    button {
        &.active {
            background: $vve-vivid-blue;
        }
    }

    &.list-group {
        &::-webkit-scrollbar-thumb {
            background: $vve-department-list-group;
        }
    }

    //override
    .list-group-item:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .list-group-item {
        border-left: none;
        border-right: none;

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 18rem;
            font-size: 16px;
            color: $vve-black;

            @media ($mobile) {
                width: 70vw;
            }
        }

        &.list-group-item-action {
            padding-left: 0;
            padding-right: 0;

            .align-items-center {
                padding-left: 11px;
                padding-right: 11px;
            }
        }
    }

    .vve-oncall-selected-dept {
        background-color: $vve-oncall-selected-dept;
    }

    @media ($mobile) {
        width: 100%;
        max-height: 54.5rem;
    }

}

.vve-department-map-list {
    font-family: 'Lato-Bold';
    font-weight: 500;
}

.vve-oncall-no-results-found {
    background-image: url("../../assets/images/no_result_found.svg");
    background-repeat: no-repeat;
    height: 220px;
    top: 12rem;
    position: relative;
    background-size: contain;
    background-position: center center;
}

.vve-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vve-department-list-container {
    height: 721px;
    margin-top: 16px;
}