.vve-invite-guest-text-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .vve-info {
        width: 100%;
        letter-spacing: -0.02em;
        text-align: left;
    }
    .vve-multi-invite-header {
        @extend .vve-bold;
        font-weight: 400;
        color: $vve-black
    }
}