.vve-invite-guest-patient {
    font-family: $font-family-base;
    height: calc(100% - 15rem);

    .vve-invite-guest-patient-container {
        height: calc(100% - 4rem);
        overflow-y: auto;

        @media ($custom-maxw-376) {
            height: calc(100% - 6rem);
        }

        @media ($custom-maxw-351) {
            height: calc(100% - 10rem);
        }

        @media ($custom-maxw-308) {
            height: calc(100% - 13rem);
        }

        @media ($custom-maxw-281) {
            height: calc(100% - 15rem);
        }

        @media (orientation: landscape) {
            height: calc(100% - 6rem);
        }

        .vve-invite-error-message {
            float: left;

            label {
                font-size: 12px;
                color: $vve-guest-patient-msg-color;
            }
        }
    }

    .vve-add-guest-patient-radiobtn {
        &.disabled {
            color: $vve-grey;

            &.form-check {
                &.form-check-inline {
                    .form-check-input {
                        box-shadow: none;
                        pointer-events: none;

                        &:checked {
                            background-color: $vve-grey;
                            border-color: $vve-grey;
                            background-image: url("../../assets/images/vve-radio-btn-grey.svg");
                        }
                    }
                }
            }
        }


    }

    .vve-invite-guest-patient-btnadd {
        font-size: 1.3rem;
        border-radius: 15px;
        width: 10rem;
        height: 3rem;
        background-color: $vve-vivid-blue;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 8px;

        img {
            width: 19.7px;
            height: 12.4px;
        }

        &.disabled {
            background-color: $vve-guest-patient-btn-disabled-bgcolor;
            color: $vve-grey;
            border: none;
            pointer-events: none;
        }
    }

    .vve-invite-guest-patient-btnremove {
        font-size: 1.3rem;
        text-decoration: none;
        float: right;
        padding: 0;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $vve-guest-patient-btn-remove-color;
    }

    .vve-invite-guest-patient-tbgroup {
        margin-top: 10px;

        .vve-invite-guest-patient-tb {

            width: 282px;
            height: 32px;
            font-size: 13px;
            box-shadow: none;
            border-radius: 8px;

            &:focus {
                border-color: $vve-vivid-blue;
            }

            &.vve-invite-validation-error {
                color: $vve-bright-red;
                border-color: $vve-bright-red;
                background-color: $vve-error-bgcolor;

                &:focus {
                    border-color: $vve-bright-red;
                }
            }
        }

        .vve-form-tb-search {
            input:enabled:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 1000px $vve-white inset;
            }

            input:disabled:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 1000px $input-disabled inset;
            }

            input {
                border-radius: 0.6rem;
                font-size: 1.3rem;
                line-height: 2rem;
            }

            .vve-invite-validation-error {
                background-image: none;
                padding-right: 0.55em;
                color: $vve-bright-red;
                background-color: $vve-light-red;
            }
            input[type="search"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
                opacity: 1;
                cursor: pointer;
            }

            input[type="search"][class~="vve-invite-validation-error"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
                height: 1.5rem;
                width: 1.5rem;
                display: block;
                background-image: url("/assets/images/vve-error-cancel-search.svg");
                background-repeat: no-repeat;
                background-size: 1.5rem;
                opacity: 0.5;
                cursor: pointer;
            }
        }

        /*overrides*/
        .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
            padding-right: 24px;
        }
    }
}
